import styled from "styled-components";
import { Form } from "@unform/web";
import { MdKeyboardArrowRight, MdSubdirectoryArrowRight } from "react-icons/md";
import { Typography } from "../../components";

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 100vh;
  background-image: url("https://imgur.com/FXpw9Qb.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #fea000;
  overflow: hidden;
`;
const ContainerLeft = styled.div`
  background-color: #77aa77;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 2 1'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='0' x2='0' y1='0' y2='1'%3E%3Cstop offset='0' stop-color='%2377aa77'/%3E%3Cstop offset='1' stop-color='%234fd'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='0' y2='1'%3E%3Cstop offset='0' stop-color='%23cf8' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23cf8' stop-opacity='1'/%3E%3C/linearGradient%3E%3ClinearGradient id='c' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='2' y2='2'%3E%3Cstop offset='0' stop-color='%23cf8' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23cf8' stop-opacity='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect x='0' y='0' fill='url(%23a)' width='2' height='1'/%3E%3Cg fill-opacity='0.5'%3E%3Cpolygon fill='url(%23b)' points='0 1 0 0 2 0'/%3E%3Cpolygon fill='url(%23c)' points='2 1 2 0 0 0'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
  display: flex;
  justify-content: flex-end;
  /* background-image: url("https://images.pexels.com/photos/5088178/pexels-photo-5088178.jpeg"); */
  width: 100%;
  height: 100vh;
  /* background-size: cover; */
  align-items: center;
  /* background-attachment: fixed; */

  @media (max-width: 1024px) {
    justify-content: center;
  }
`;

const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  /* width: 400px; */
  max-height: 310px;
  justify-content: space-around;
`;

const ContainerAuth = styled.div`
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin-right: 30px;
  // padding: 60px 30px;
  /* width: 400px; */
  position: relative;
  @media (max-width: 1024px) {
    /* width: 100%; */
  }
  @media (max-width: 720px) {
    width: 100%;
    height: 100%;
    margin-right: initial;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 30px;
  }
`;

const BoxText = styled.div`
  margin-bottom: 25px;
`;

const Title = styled.div`
  font-size: 25px;
  margin: 15px 0px;
`;

const InputPassword = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const TextPassword = styled(Typography)`
  margin-top: 15px;
  text-align: center;
`;

const UserServices = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  height: 100%;
  background-color: #fff;
  padding: 0px 40px;
  @media (max-width: 1024px) {
    /* width: 100%; */
    padding: 0px 10px;
  }
  @media (max-width: 720px) {
    width: 100%;
    height: 100%;
    margin-right: initial;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 30px;
  }
`;
const UserInfo = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: space-around; */
`;
const Services = styled.div``;
const CardService = styled.div`
  max-width: 400px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-top: 10px;
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[50]};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    background-color: ${(props) => props.theme.palette.grey[100]};
  }
  &:hover .IconCard {
    margin-right: initial;
  }
`;

const TextEmail = styled.span`
  margin: 0 10px;
`;

const ContentAuth = styled.div`
  margin: 10px;
`;
const ContentCard = styled.div``;

const IconCard = styled(MdKeyboardArrowRight)`
  font-size: 30px;
  transition: 0.3s;
  margin-right: 10px;
  color: ${(props) => props.theme.palette.grey[500]};
`;

const IconDirect = styled(MdSubdirectoryArrowRight)`
  font-size: 30px;
  color: ${(props) => props.theme.palette.grey[500]};
  padding: 0 10px;
`;

const MensageError = styled.span`
  color: red;
  margin-bottom: 15px;
`;

const TextNotAcess = styled(Typography)`
  margin: 0 5px;
  color: #000000a3;
  font-weight: 500;
  cursor: pointer;
`;

const TextTermsOfUse = styled(Typography)`
  margin: 0 5px;
  color: #000000a3;
  font-weight: 500;
  cursor: pointer;
`;

const FooterAuth = styled.div`
  position: absolute;
  bottom: 11px;
  right: 15px;
`;

const BoxLogin = styled.div`
  display: FLEX;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
`;

const ContainerImgLeft = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 720px) {
    display: none;
  }
`;
const ImgLeft = styled.img`
  /* background-color: #fff; */
  width: 400px;
`;

export {
  Container,
  ContainerLeft,
  ContainerAuth,
  FormContainer,
  BoxText,
  Title,
  InputPassword,
  TextPassword,
  UserServices,
  UserInfo,
  Services,
  CardService,
  TextEmail,
  ContentAuth,
  ContentCard,
  IconCard,
  IconDirect,
  MensageError,
  TextNotAcess,
  TextTermsOfUse,
  FooterAuth,
  BoxLogin,
  ImgLeft,
  ContainerImgLeft,
};
